import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledPriceBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [50]
  })
)

export const H4Bps = css(
  mq({
    textAlign:['left', 'left', 'center', 'center']
  })
)

export const styledPrice = css`
  ${styledPriceBps};
  h4 {
    ${H4Bps};
    color: rgba(0, 0, 0, 0.87);
  }
`

export const H3HeaderBps = css(
  mq({
    maxWidth: [328, 422, 680, 680],
    marginLeft: [0, 89, 200, 302],
    marginRight: [0, 89, 200, 302],
    marginBottom: [16],
    letterSpacing: [0.25],
    textAlign: ['center'],
    fontSize: [33.6, 33.6, 33.6, 47.9],
    fontWeight: [600, 600, 600, 'normal']
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, 422, 856, 856],
    marginLeft: [0, 89, 112, 214],
    marginRight: [0, 89, 112, 214]
  })
)

export const header = css`
  display: flex;
  text-align: center;
  h3 {
    ${H3HeaderBps};
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    ${PHeaderBps};
  }
`

export const currency = css`
  font-size: 13.8px;
  font-weight: 600;
  line-height: 1.74;
  letter-spacing: 0.1px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
`

export const listServer = css`
  h6 {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
    letter-spacing: 0.25px;
  }
  img {
    width: 230px;
    margin-bottom: 20px;
  }
`

export const listManager = css`
  display: flex;
  align-items: center;
  img {
    margin-right: 16px;
    width: 100px;
  }
  h6 {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
    letter-spacing: 0.25px;
  }
`

export const item = css`
  transition: .3s ease-out,transform .3s ease-out,opacity .2s ease-out;
  transition-delay: .1s;
  transition: all .25s linear;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.12);
  :hover {
    transform: translate(0, -4px);
    box-shadow: -4px 10px 20px 0px rgba(0,0,0,0.12);
  }
`

export const styledRecommendedPlans = css`
  background-color: #f3f5f5;
  padding-bottom: 50px;
`

export const styledCloudConfig = css`
  padding-bottom: 50px;
`