import React from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'
import ArrowR from '@material-ui/icons/ArrowForward'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
// import H3 from '../components/H3'
import H4 from '../components/H4'
import H5 from '../components/H5'
import Paragraph from '../components/Paragraph'
import Subtitle from '../components/SubTitle2'
import Div from '../components/Div/Div.styles'
import ds from '../theme'
import { css } from '@emotion/core'

import { Container, mq } from '../components/styles/Layout.styles'
import { 
  styledOurCertificates,
  header
} from '../components/styles/OurCertificates.styles'
import { 
  item
} from '../components/styles/DedicatedServersPrice.styles'

const Awards = ({
  licenceName,
  licenceDescription,
  licenceIso,
  licenceBenefits,
  benefitsDescription,
  benefitsDescriptionItem2,
  benefitsDescriptionItem3,
  benefitsDescriptionItem4,
}) => {
  return (
    <div
      style={{
        padding: '50px 24px',
        backgroundColor: '#4e5159',
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        borderRadius: 12,
        height: 1164
      }}
      css={
        [css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        ),
        item 
      ]
      }
    >
      <Div
        flexDirection='column'
        alignItems='center'
      >
        <H4
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            letterSpacing: 0.25,
            marginBottom: 16,
            width: 296,
            textAlign: 'center',
            color: '#f9fafa'
          }}
        >
          {licenceName}
        </H4>
        <Div
          style={{
            width: 200,
            height: 60,
            borderRadius: 30,
            border: 'solid 1px #fff',
            justifyContent: 'center',
            marginBottom: 10
          }}
        >
          <Subtitle
            style={{
              fontSize: 14,
              fontWeight: 600,
              lineHeight: 1.5,
              letterSpacing: 2.38,
              textAlign: 'center',
              color: '#f9fafa',
              whiteSpace: 'pre-wrap'
            }}
          >
            {licenceIso}
          </Subtitle>
        </Div>
        <Div 
          style={{
            width: 200,
            height: 1,
            borderBottom: 'solid 1px #e0e0e0',
            margin: '20px 0'
          }}
        />
        <Paragraph
          style={{
            fontSize: 14,
            lineHeight: 1.7,
            textAlign: 'center',
            color: '#f9fafa'
          }}
        >
          {licenceDescription}
        </Paragraph>
        <Div 
          style={{
            width: 200,
            height: 1,
            borderBottom: 'solid 1px #e0e0e0',
            margin: '20px 0'
          }}
        />
        <H5 
          style={{
            fontSize: 23.7,
            textAlign: 'center',
            fontWeight: 500,
            color: '#f9fafa',
            marginTop: 10,
            marginBottom: 24,
            whiteSpace: 'pre-wrap'
          }}>
          {licenceBenefits}
        </H5>
        <Div
          style={{
            display: '-webkit-box',
            marginBottom: 16
          }}
        >
          <ArrowR 
            style={{
              color: 'rgba(255, 255, 255, 0.54)'
            }}
          />
          <Paragraph
            style={{
              fontSize: 14,
              lineHeight: 1.7,
              textAlign: 'left',
              width: 240,
              marginLeft: 16,
              color: '#f9fafa'
            }}
          >
            {benefitsDescription}
          </Paragraph>
        </Div>
        <Div
          style={{
            display: '-webkit-box',
            marginBottom: 16
          }}
        >
          <ArrowR 
            style={{
              color: 'rgba(255, 255, 255, 0.54)'
            }}
          />
          <Paragraph
            style={{
              fontSize: 14,
              lineHeight: 1.7,
              textAlign: 'left',
              width: 240,
              marginLeft: 16,
              color: '#f9fafa'
            }}
          >
            {benefitsDescriptionItem2}
          </Paragraph>
        </Div>
        <Div
          style={{
            display: '-webkit-box',
            marginBottom: 16
          }}
        >
          <ArrowR 
            style={{
              color: 'rgba(255, 255, 255, 0.54)'
            }}
          />
          <Paragraph
            style={{
              fontSize: 14,
              lineHeight: 1.7,
              textAlign: 'left',
              width: 240,
              marginLeft: 16,
              color: '#f9fafa'
            }}
          >
            {benefitsDescriptionItem3}
          </Paragraph>
        </Div>
        <Div
          style={{
            display: '-webkit-box',
          }}
        >
          <ArrowR 
            style={{
              color: 'rgba(255, 255, 255, 0.54)'
            }}
          />
          <Paragraph
            style={{
              fontSize: 14,
              lineHeight: 1.7,
              textAlign: 'left',
              width: 240,
              marginLeft: 16,
              color: '#f9fafa'
            }}
          >
            {benefitsDescriptionItem4}
          </Paragraph>
        </Div>
      </Div>
    </div>
  )
}

const ImportantLicence = ({
  licenceName,
  licenceDescription,
  licenceIso,
  licenceBenefits,
  benefitsDescription,
  benefitsDescriptionItem2,
  benefitsDescriptionItem3,
  benefitsDescriptionItem4,
}) => {
  return (
    <div
      style={{
        padding: '50px 24px',
        backgroundColor: '#9013fe',
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        borderRadius: 12
      }}
      css={
        [css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        ),
        item 
      ]
      }
    >
      <Div
        flexDirection='column'
        alignItems='center'
      >
        <H4
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            letterSpacing: 0.25,
            marginBottom: 16,
            width: 296,
            textAlign: 'center',
            color: '#f9fafa'
          }}
        >
          {licenceName}
        </H4>
        <Div
          style={{
            width: 200,
            height: 60,
            borderRadius: 30,
            border: 'solid 1px #fff',
            justifyContent: 'center',
            marginBottom: 10
          }}
        >
          <Subtitle
            style={{
              fontSize: 14,
              fontWeight: 600,
              lineHeight: 1.5,
              letterSpacing: 2.38,
              textAlign: 'center',
              color: '#f9fafa',
              whiteSpace: 'pre-wrap'
            }}
          >
            {licenceIso}
          </Subtitle>
        </Div>
        <Div 
          style={{
            width: 200,
            height: 1,
            borderBottom: 'solid 1px #e0e0e0',
            margin: '20px 0'
          }}
        />
        <Paragraph
          style={{
            fontSize: 14,
            lineHeight: 1.7,
            textAlign: 'center',
            color: '#f9fafa'
          }}
        >
          {licenceDescription}
        </Paragraph>
        <Div 
          style={{
            width: 200,
            height: 1,
            borderBottom: 'solid 1px #e0e0e0',
            margin: '20px 0'
          }}
        />
        <H5 
          style={{
            fontSize: 23.7,
            textAlign: 'center',
            fontWeight: 500,
            color: '#f9fafa',
            marginTop: 10,
            marginBottom: 24,
            whiteSpace: 'pre-wrap'
          }}>
          {licenceBenefits}
        </H5>
        <Div
          style={{
            display: '-webkit-box',
            marginBottom: 16
          }}
        >
          <ArrowR 
            style={{
              color: 'rgba(255, 255, 255, 0.54)'
            }}
          />
          <Paragraph
            style={{
              fontSize: 14,
              lineHeight: 1.7,
              textAlign: 'left',
              width: 240,
              marginLeft: 16,
              color: '#f9fafa'
            }}
          >
            {benefitsDescription}
          </Paragraph>
        </Div>
        <Div
          style={{
            display: '-webkit-box',
            marginBottom: 16
          }}
        >
          <ArrowR 
            style={{
              color: 'rgba(255, 255, 255, 0.54)'
            }}
          />
          <Paragraph
            style={{
              fontSize: 14,
              lineHeight: 1.7,
              textAlign: 'left',
              width: 240,
              marginLeft: 16,
              color: '#f9fafa'
            }}
          >
            {benefitsDescriptionItem2}
          </Paragraph>
        </Div>
        <Div
          style={{
            display: '-webkit-box',
            marginBottom: 16
          }}
        >
          <ArrowR 
            style={{
              color: 'rgba(255, 255, 255, 0.54)'
            }}
          />
          <Paragraph
            style={{
              fontSize: 14,
              lineHeight: 1.7,
              textAlign: 'left',
              width: 240,
              marginLeft: 16,
              color: '#f9fafa'
            }}
          >
            {benefitsDescriptionItem3}
          </Paragraph>
        </Div>
        <Div
          style={{
            display: '-webkit-box',
          }}
        >
          <ArrowR 
            style={{
              color: 'rgba(255, 255, 255, 0.54)'
            }}
          />
          <Paragraph
            style={{
              fontSize: 14,
              lineHeight: 1.7,
              textAlign: 'left',
              width: 240,
              marginLeft: 16,
              color: '#f9fafa'
            }}
          >
            {benefitsDescriptionItem4}
          </Paragraph>
        </Div>
      </Div>
    </div>
  )
}

const LicenceItem = ({
  licenceName,
  licenceDescription,
  licenceIso,
  licenceBenefits,
  benefitsDescription,
}) => {
  return (
    <div
      style={{
        padding: '50px 24px',
        backgroundColor: '#ffffff',
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        borderRadius: 12
      }}
      css={
        [css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        ),
        item 
      ]
      }
    >
      <Div
        flexDirection='column'
        alignItems='center'
      >
        <H4
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            letterSpacing: 0.25,
            marginBottom: 16,
            width: 296,
            textAlign: 'center'
          }}
        >
          {licenceName}
        </H4>
        <Div
          style={{
            width: 200,
            height: 60,
            borderRadius: 30,
            border: 'solid 1px #44474e',
            justifyContent: 'center',
            marginBottom: 10
          }}
        >
          <Subtitle
            style={{
              fontSize: 14,
              fontWeight: 600,
              lineHeight: 1.5,
              letterSpacing: 2.38,
              textAlign: 'center',
              color: '#44474e',
              whiteSpace: 'pre-wrap'
            }}
          >
            {licenceIso}
          </Subtitle>
        </Div>
        <Div 
          style={{
            width: 200,
            height: 1,
            borderBottom: 'solid 1px #e0e0e0',
            margin: '20px 0'
          }}
        />
        <Paragraph
          style={{
            fontSize: 14,
            lineHeight: 1.7,
            textAlign: 'center',
            color: 'rgba(0, 0, 0, 0.6)'
          }}
        >
          {licenceDescription}
        </Paragraph>
        <Div 
          style={{
            width: 200,
            height: 1,
            borderBottom: 'solid 1px #e0e0e0',
            margin: '20px 0'
          }}
        />
        <H5 
          style={{
            fontSize: 23.7,
            textAlign: 'center',
            fontWeight: 500,
            color: 'rgba(0, 0, 0, 0.87)',
            marginTop: 10,
            marginBottom: 24,
            whiteSpace: 'pre-wrap'
          }}>
          {licenceBenefits}
        </H5>
        <Div
          style={{
            display: '-webkit-box'
          }}
        >
          <ArrowR 
            style={{
              color: 'rgba(78, 81, 89, 0.54)'
            }}
          />
          <Paragraph
            style={{
              fontSize: 14,
              lineHeight: 1.7,
              textAlign: 'left',
              width: 240,
              marginLeft: 16,
              color: 'rgba(0, 0, 0, 0.6)'
            }}
          >
            {benefitsDescription}
          </Paragraph>
        </Div>
      </Div>
    </div>
  )
}

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledOurCertificates}>
      <Container>
        <Row
          flexDirection={['column', 'row']}
          css={header}
        >
          <Column
            width={[1]}
          >
            <span>POR QUE A HOSTDIME?</span>
            <H4>Premiações e Certificados</H4>
          </Column>
        </Row>
        <Row
          justifyContent={'center'}
          flexDirection={['column', 'column', 'column', 'row']}
          alignItems={'initial'}
          mt={[30, 50]}
        >
          <Column
            mb={[30]}
          >
            <Div
              maxWidth={['100%', 328]}
              height={[777]}
            >
              <Awards
                licenceName='Premiação'
                licenceIso={`DCD AWARDS`}
                licenceDescription='Concedida pelo principal veículo de comunicação do segmento, a editora e empresa global de meios de comunicação B2B, Data Center Dynamics, analisando a estrutura dos Data Centers da América Latina.'
                licenceBenefits={`Diferenciais`}
                benefitsDescription='Melhor Data Center de um Fornecedor de Serviços em 2018;'
                benefitsDescriptionItem2='Melhor Fornecedor de Colocation em 2017 e 2018;'
                benefitsDescriptionItem3='Fortalecimento da importância da inovação no setor;'
                benefitsDescriptionItem4='Consolidação da HostDime Brasil como um dos melhores Data Centers da América Latina.'
              />
            </Div>
          </Column>
          <Column
            mb={[30]}
          >
            <Div
              maxWidth={['100%', 328]}
            >
              <ImportantLicence
                licenceName='Certificado'
                licenceIso={`TIER III`}
                licenceDescription='O Uptime Institute Professional Services é o único fornecedor de consultas e certificações para o Sistema de Classificação Tier, padrão que mede o nível de confiabilidade da infraestrutura de um local onde funciona um centro de processamento de dados. Quanto maior o nível, maior a disponibilidade de dados do hardware no local.'
                licenceBenefits={`Benefícios para\n a empresa`}
                benefitsDescription='Caminho de distribuição único não-redundante que serve os equipamentos de TI;'
                benefitsDescriptionItem2='Infraestrutura do local básico, infraestrutura do local com componentes de capacidade redundante e infraestrutura local paralelamente sustentável que conferem disponibilidade de 99,982%;'
                benefitsDescriptionItem3='Múltiplos caminhos de distribuição independentes, servindo os equipamentos de TI;'
                benefitsDescriptionItem4='Equipamentos de TI dual-alimentados e totalmente compatíveis com a topologia da arquitetura do local.'
              />
            </Div>
          </Column>
          <Column
            mb={[30]}
            order={[3]}
          >
            <Div>
              <Div
                maxWidth={['100%', 328]}
                style={{
                  marginBottom: 30
                }}
              >
                <LicenceItem
                  licenceName='Certificado'
                  licenceIso={`ISO 27001`}
                  licenceDescription='A International Organization for Standardization, mais conhecida como ISO, é a principal certificadora em desenvolvimento e publicação de normas internacionais. Sua norma ISO 27001 é o padrão mundial para gestão da Segurança da Informação em ambientes de TI. Ela define o modelo adequado de estabelecimento, implementação, operação, monitoramento, revisão e gestão para Sistemas de Gestão de Segurança da Informação.'
                  licenceBenefits={`Benefícios para\n a empresa`}
                  benefitsDescription='A garantia de que os dados da sua organização serão tratados de acordo com elevados padrões de gestão e proteção ao nível de Segurança da Informação.'
                />
              </Div>
              <Div
                maxWidth={['100%', 328]}
              >
                <LicenceItem
                  licenceName='Certificado'
                  licenceIso={`SOC 2 Tipo 2`}
                  licenceDescription='A certificação SOC 2 Tipo 2 garante rigorosos e abrangentes controles de segurança da informação pela organização.'
                  licenceBenefits={`Benefícios para\n a empresa`}
                  benefitsDescription='Uma organização que obtém a certificação SOC 2 Tipo 2 provou que seu sistema foi feito para manter os dados sensíveis de seus clientes de forma segura.'
                />
              </Div>
            </Div>
          </Column>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Premiações e Certificados' />
      <PageTemplate
        title='Premiações e Certificados'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
