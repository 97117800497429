import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const ourCertificatesBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [30, 30, 50, 50]
  })
)

export const styledOurCertificates = css`
  ${ourCertificatesBps};
`

/* HEADER */
export const H4HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680]
  })
)

export const headerMarginBps = css(
  mq({
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302]
  })
)

export const headerAlignBps = css(
  mq({
    textAlign: ['center']
  })
)

export const header = css`
  display: flex;
  ${headerAlignBps};
  span {
    ${headerMarginBps};
    font-size: 11.8px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h4 {
    ${H4HeaderBps};
    ${headerMarginBps};
    margin-top: 8px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
  }
`
/* CERTIFICATES */
export const certificatesBps = css(
  mq({
    marginTop: [30, 30, 50, 50],
    marginLeft: [0, 89, 24, 87],
    marginRight: [0, 89, 24, 126]
  })
)

export const certificateItemBps = css(
  mq({
    width: [328, 422, 328, 328],
    height: [1094]
  })
)

export const H4ItemBps = css(
  mq({
    maxWidth: [280],
    marginLeft: [24, 71, 24, 24],
    marginRight: [24, 71, 24, 24],
    marginTop: [50],
    marginBottom: [16]
  })
)

export const H5ItemBps = css(
  mq({
    width: [280],
    marginLeft: [24, 71, 24, 24],
    marginRight: [24, 71, 24, 24],
    marginTop: [39.5],
    marginBottom: [24],
    textAlign: ['center']
  })
)

export const PItemHeaderBps = css(
  mq({
    maxWidth: [280, 374, 280, 280],
    marginLeft: [24, 71, 24, 24],
    marginRight: [24, 71, 24, 24],
    textAlign: ['center']
  })
)

export const PBenefitsBps = css(
  mq({
    width: [240, 334, 240, 240],
    textAlign: ['left']
  })
)

export const lineItemBps = css(
  mq({
    width: [200, 276, 200, 200],
    marginLeft: [64, 73, 64, 64],
    marginRight: [64, 73, 64, 64],
  })
)

export const certificates = css`
  ${certificatesBps};
`

export const certificateItem = css`
  ${certificateItemBps};
  background: #fff;
  border-radius: 16px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  margin-bottom: 30px;
`

export const violetBox = css`
  background: #9013fe;
  h4 {
    color: #f9fafa;
  }
  h5 {
    color: #f9fafa;
  }
  p {
    color: #f9fafa !important;
  }
  span {
    color: #f9fafa !important;
  }
`

export const certificateHeader = css`
  h4 {
    ${H4ItemBps};
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    ${PItemHeaderBps};
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 23.5px;
    margin-bottom: 23.5px;
  }
`
export const certificateBenefits = css`
  h5 {
    ${H5ItemBps};
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }
  img {
    margin-left: 24px;
  }
  p {
    ${PBenefitsBps};
    line-height: 1.71;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 16px;
    margin-bottom: 16px;
  }
`

export const certificateBorder = css`
  border-radius: 30px;
  border: solid 1px #44474e;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  span {
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.49;
    letter-spacing: 2.38px;
    text-align: center;
    color: #44474e;
    padding-top: 9px;
    padding-bottom: 9px;
  }
`

export const lineItem = css`
  ${lineItemBps};
  border: solid 1px #e0e0e0;
`